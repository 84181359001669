export enum YmGoalAppointment {
  /// Клик плашки с врачами на главной.
  HomeDoctorBannerAppointmentCatalogOpen = 'homeDoctorBannerAppointmentCatalogOpen',
  HomeDoctorBannerAppointmentPageOpen = 'homeDoctorBannerAppointmentPageOpen',
  /// Клик на Прием врачей в нижнем меню.
  MobileNavigationDoctorAppointmentCatalogOpen = 'mobileNavigationDoctorAppointmentCatalogOpen',
  MobileNavigationDoctorAppointmentPageOpen = 'mobileNavigationDoctorAppointmentPageOpen',
  /// Клик на Запись к врачу в верхнем навигационном меню.
  HeaderNavigationDoctorAppointmentCatalogOpen = 'headerNavigationDoctorAppointmentCatalogOpen',
  HeaderNavigationDoctorAppointmentPageOpen = 'headerNavigationDoctorAppointmentPageOpen',
  /** Открытие модалки записи на врачебную услугу со страницы каталога/врачебные услуги. */
  CatalogDoctorAppointmentModalOpen = 'catalogDoctorAppointmentModalOpen',
  /** Открытие модалки записи на врачебную услугу из страницы конкретной врачебной услуги. */
  CatalogItemDoctorAppointmentModalOpen = 'catalogItemDoctorAppointmentModalOpen',
  /** Открытие модалки записи на врачебную услугу из поисковой выдачи. */
  SearcherDoctorAppointmentModalOpen = 'searcherDoctorAppointmentModalOpen',
  /** Успешная отправка формы на модалке записи на врачебную услугу. */
  DoctorAppointmentModalSubmit = 'doctorAppointmentModalSubmit',
  /** Изменение специальности в фильтре поиска врачей. */
  DoctorAppointmentPageSpecialityChange = 'doctorAppointmentPageSpecialityChange',
  /** Изменение времени приема в фильтре поиска врачей. */
  DoctorAppointmentPageTimeChange = 'doctorAppointmentPageTimeChange',
  /** Изменение ДЦ в фильтре поиска врачей. */
  DoctorAppointmentPageCentersChange = 'doctorAppointmentPageCentersChange',
  /** Установка чекбокса "Детский врач" в активное положение. */
  DoctorAppointmentPageBabyDoctorSelected = 'doctorAppointmentPageBabyDoctorSelected',
  /** Установка чекбокса "Детский врач" в неактивное положение. */
  DoctorAppointmentPageBabyDoctorDeselected = 'doctorAppointmentPageBabyDoctorDeselected',
  /** Открытие страницы оформления записи к конкретному врачу. */
  DoctorAppointmentRegistrationPageOpen = 'doctorAppointmentRegistrationPageOpen',
  /** Успешное оформление записи к врачу. */
  DoctorAppointmentRegistrationPageSubmit = 'doctorAppointmentRegistrationPageSubmit',
}

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { BehaviorSubject, map, Subject, take, takeUntil, timer } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-modal-success-send',
  templateUrl: './modal-success-send.component.html',
  styleUrls: ['./modal-success-send.component.scss'],
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalSuccessSendComponent implements OnInit, OnDestroy {
  constructor(private modal: NzModalRef) {}

  @Input() title = '';
  @Input() text = '';
  @Input() time = 0;

  time$ = new BehaviorSubject<number>(0);
  protected isTimerVisible = false;
  private ngUnsubscribe$ = new Subject<void>();

  ngOnInit(): void {
    this.isTimerVisible = this.time > 3;
    this.startTimer(this.time);
  }

  startTimer(nSec: number): void {
    timer(0, 1000)
      .pipe(
        take(nSec + 1),
        map((incCounter) => nSec - incCounter),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe({
        next: (secondsLeft) => this.time$.next(secondsLeft),
        complete: () => this.modal.close(),
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}

import { EventEmitter, Injectable } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { ModalOptions, OnClickCallback } from 'ng-zorro-antd/modal/modal-types';

import { WarningModalComponent } from '@shared/components/warning-modal/warning-modal.component';
import { ModalErrorType } from '@shared/enums/modal-error-type';
import { ModalSize } from '@shared/enums/modal-sizes';
import { PlatformService } from '@shared/services/platform.service';
import { ModalSuccessSendComponent } from '@shared/components/modal-success-send/modal-success-send.component';
import { BusinessLogicErrorMap } from '@shared/constants/business-errors';

/** Объединяет деструкцию модалки и кастомное поведение на событие выбора "Отмены" в модалке. */
class ModalCancelBehaviorBuilder {
  /** Позволяет хранить кастомное поведение, назначенное на событие отмены в модалке. */
  customCancelBehavior?: (componentInstance: any) => any;
  /** Позволяет хранить функцию, вызывающую деструкцию модалки. */
  modalDestructor?: () => void;
  /**
   * Дружит деструкцию модалки и кастомное поведение на событие выбора "Отмены" в модалке.
   * @returns Коллбек, с комплексным действием.
   */
  build(): (componentInstance: any) => any {
    return (componentInstance: any) => {
      if (this.modalDestructor) {
        this.modalDestructor();
      } else {
        console.error('Необходимо назначить функцию уничтожения модалки.');
      }
      return this.customCancelBehavior
        ? this.customCancelBehavior(componentInstance)
        : void 0;
    };
  }
}

/** Управляет созданием и деструкцией модалок. */
@Injectable({ providedIn: 'root' })
export class ModalService {
  constructor(
    private platform: PlatformService,
    private modal: NzModalService
  ) {}

  /** Ссылка на последнюю созданную модалку. */
  private currentRef: NzModalRef | undefined;
  /** Ссылки на текущие модалки модалку. */
  private allRefs: NzModalRef[] = [];

  /** Время (в мс) существования модалки, если оно не было указано при создании. */
  private readonly defaultDuration = 10000000;

  /**
   * Создает модалку (только на стороне браузера).
   * @param options Конфигурация модалки.
   * @param duration Длительность существования модалки.
   */
  create(options: ModalOptions, duration = this.defaultDuration): void {
    if (this.platform.isServer) return;
    const modalCancelBehaviorBuilder = new ModalCancelBehaviorBuilder();
    const modalOptions = this.createModalOptions(
      options,
      modalCancelBehaviorBuilder
    );
    const modalRef = this.modal.create(modalOptions);
    this.setModalDestructors(
      modalRef,
      modalOptions,
      modalCancelBehaviorBuilder,
      duration
    );
    this.allRefs.push(modalRef);
    this.currentRef = modalRef;
  }

  /**
   * Создает красивую модалку об успехе (автоматически закрывается через 2 секунды).
   * @param message Текст модалки.
   */
  createSuccess(
    title?: string,
    message?: string,
    width = ModalSize.Message,
    duration = 2
  ): void {
    this.create({
      nzTitle: '',
      nzContent: ModalSuccessSendComponent,
      nzComponentParams: {
        title: title,
        text: message,
        time: duration,
      },
      nzStyle: {
        width: width,
      },
      nzClassName: 'modal-with-bg',
      nzFooter: null,
    });
  }

  /**
   * Создает простую модалку об ошибке.
   * @param message Текст модалки.
   */
  createError(message: string): void {
    this.createWithErrorIcon(ModalErrorType.Error, message);
  }

  createBusinessLogicError(error: string) {
    this.createWithErrorIcon(
      ModalErrorType.Error,
      BusinessLogicErrorMap[<string>error] || error
    );
  }

  /**
   * Создает простую модалку с инфой.
   * @param message Текст модалки.
   */
  createInfo(message: string): void {
    this.createWithErrorIcon(ModalErrorType.Info, message);
  }

  /** Закрывает последнюю созданную через этот сервис модалку. */
  destroy(): void {
    this.currentRef?.destroy();
  }

  /** Закрывает все накопившиеся созданные через этот сервис модалки. */
  destroyAll(): void {
    this.allRefs.forEach((modalRef) => modalRef.destroy());
    this.allRefs = [];
  }

  /**
   * Принадлежит ли HTML-нода какой-либо модалке, созданной этим сервисом.
   * @param target Нода для проверки.
   * @returns true - если принадлежит, иначе false.
   */
  isPartOfAnyModal(target?: Node): boolean {
    if (!target) return false;
    for (const modal of this.allRefs) {
      if (modal.getElement().contains(target ?? null)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Возвращает строку предупреждения о перезаписи EventEmitter, использующегося для деструкции модалки.
   * @param emitterName Имя EventEmitter в ModalOptions.
   * @returns Строка с предупреждением.
   */
  private getEmitterWillBeOverwrittenMessage(
    emitterName: keyof ModalOptions
  ): string {
    return `${emitterName} будет перезаписан. Это может повлиять на деструкцию модалки.`;
  }

  /**
   * Конструирует опции для будущей модалки с учетом пользовательской конфигурации.
   * @param options Пользовательская конфигурация модалки.
   * @param modalCancelBehaviorBuilder Билдер коллбека деструктора модалки.
   * @returns Опции для создания модалки.
   */
  private createModalOptions(
    options: ModalOptions,
    modalCancelBehaviorBuilder: ModalCancelBehaviorBuilder
  ): ModalOptions {
    const afterClose = new EventEmitter();
    const onCancel = new EventEmitter();

    if (options.nzAfterClose) {
      console.debug(this.getEmitterWillBeOverwrittenMessage('nzAfterClose'));
    }
    if (options.nzOnCancel) {
      console.debug(this.getEmitterWillBeOverwrittenMessage('nzOnCancel'));
    }

    const modalOptions = {
      nzOnCancel: onCancel,
      nzAfterClose: afterClose,
      nzStyle: {
        width: ModalSize.Auto,
        minWidth: ModalSize.Error,
        maxWidth: ModalSize.Form,
      },
      ...options,
    };

    // set modal destructor along with custom behavior on nzOnCancel
    if (typeof modalOptions.nzOnCancel === 'function') {
      modalCancelBehaviorBuilder.customCancelBehavior =
        modalOptions.nzOnCancel as OnClickCallback<any>;
    }
    const onCancelCallback = modalCancelBehaviorBuilder.build();
    if ('subscribe' in modalOptions.nzOnCancel) {
      modalOptions.nzOnCancel.subscribe(onCancelCallback);
    } else {
      modalOptions.nzOnCancel = onCancelCallback;
    }

    return modalOptions;
  }

  /**
   * Добавляет обработчики и таймер для деструкции созданной модалки.
   * @param modalRef Созданная модалка.
   * @param modalOptions Контейнер опций модалки.
   * @param modalCancelBehaviorBuilder Билдер коллбека деструктора модалки.
   * @param duration Длительность существования модалки (в мс).
   */
  private setModalDestructors(
    modalRef: NzModalRef | undefined,
    modalOptions: ModalOptions,
    modalCancelBehaviorBuilder: ModalCancelBehaviorBuilder,
    duration: number
  ): void {
    modalCancelBehaviorBuilder.modalDestructor = () => modalRef?.destroy();
    const timer = setTimeout(modalCancelBehaviorBuilder.build(), duration);
    modalOptions.nzAfterClose?.subscribe(() => clearTimeout(timer));
  }

  /**
   * Создает простую модалку с иконкой и сообщением.
   * @param type Тип модалки (влияет на иконку).
   * @param contentForInnerHtml Текст модалки (может содержать HTML).
   * @param duration Длительность существования модалки (в мс).
   */
  private createWithErrorIcon(
    type: ModalErrorType,
    contentForInnerHtml: string,
    title?: string,
    duration = this.defaultDuration
  ): void {
    this.create(
      {
        nzContent: WarningModalComponent,
        nzComponentParams: {
          title,
          content: contentForInnerHtml,
          errorType: type,
        },
        nzStyle: {
          width:
            type === ModalErrorType.Error ? ModalSize.Error : ModalSize.Message,
        },
        nzFooter: null,
      },
      duration
    );
  }
}

import { Directive, HostListener } from '@angular/core';

/**
 * Import along with [imask] directive in order to fix validation.
 */
@Directive({
  selector: 'input[imask]',
  standalone: true,
})
export class IMaskValidationFixDirective {
  /**
   * Subscribes to change event of input with imask directive just to trigger change detection,
   * that is being eaten inside imask library logic.
   */
  @HostListener('accept')
  noop() {}
}

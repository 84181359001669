<div data-testid="send-modal-title" class="typography typography-huge mb-20"
  [innerHTML]="title"
></div>
<div data-testid="send-modal-description" class="typography typography-h4"
  [innerHTML]="text"
>
</div>
<div 
  *ngIf="isTimerVisible"
  data-testid="send-modal-note" class="typography grey-400 mt-128">
  Окно закроется через {{ time$ | async }} сек.
</div>

<nz-modal>
  <div data-testid="modal-title" *nzModalTitle>
    {{ ModalTitle.MakeAppointment }}
  </div>
</nz-modal>

<ng-container *ngIf="serviceInfo">
  <span
    data-testid="modal-description"
    class="typography typography-body grey-400"
    *ngIf="storage.federalPhone$ | async as federalPhone"
  >
    Заполните форму и&nbsp;наш специалист свяжется с&nbsp;вами
    для&nbsp;подтверждения заявки. Или&nbsp;обратитесь в&nbsp;Контакт-центр
    Хеликс по&nbsp;номеру
    <a
      class="typography typography-body green-basic nowrap"
      [href]="'tel:' + federalPhone"
    >
      {{ federalPhone }}</a
    >.
  </span>

  <div
    class="grid align-items-baseline grid-gutter-12 grid-xs-column grid-nowrap bg-blue-light rounded-s p-16 mt-28"
  >
    <div class="grid-item grid align-items-baseline grid-nowrap">
      <span
        class="typography typography-body grey-700 mr-8"
        data-testid="service-type"
        >{{ TypeNomenclature.Service }}</span
      >
      <span class="tag tag-m tag-white" data-testid="hxid">{{
        serviceInfo.hxid
      }}</span>
    </div>

    <div
      class="grid-item grid-grow grid-xs-12 typography typography-headline"
      data-testid="service-title"
    >
      {{ serviceInfo.description }}
    </div>

    <div
      class="grid-item typography typography-headline typography-bold nowrap"
      data-testid="service-price"
    >
      {{ serviceInfo.price }} {{ serviceInfo.currency }}
    </div>
  </div>

  <div class="my-36 modal-appointment-divider"></div>

  <form [formGroup]="form" *ngIf="form">
    <div class="grid grid-gutter-24">
      <div class="grid-item grid-6 grid-xs-12 form-item form-item-required">
        <div
          class="typography typography-headline typography-medium form-item-label"
        >
          Фамилия, имя, отчество
        </div>
        <input
          class="input full-width"
          type="text"
          maxlength="100"
          formControlName="name"
          placeholder="Фамилия, имя, отчество"
        />

        <ng-container
          *ngIf="form.controls.name.touched && form.controls.name.errors"
        >
          <div
            class="typography typography-body red"
            *ngIf="form.controls.name.errors.required"
          >
            Обязательное поле
          </div>

          <div
            class="typography typography-body red"
            *ngIf="form.controls.name.errors.nameFormat"
          >
            Неверный формат
          </div>

          <div
            class="typography typography-body red"
            *ngIf="
              form.controls.name.errors.minlength &&
              !form.controls.name.errors.nameFormat
            "
          >
            Минимальная длина
            {{ form.controls.name.errors.minlength.requiredLength }} символа
          </div>
        </ng-container>
      </div>

      <div class="grid-item grid-6 grid-xs-12 form-item form-item-required">
        <div
          class="typography typography-headline typography-medium form-item-label"
        >
          Телефон
        </div>
        <input
          class="input full-width"
          name="phone"
          placeholder="+7 (___) ___-__-__"
          formControlName="phone"
          type="tel"
          [imask]="{ mask: phoneMask }"
        />

        <ng-container *ngIf="form.controls.phone.touched">
          <ng-container *ngIf="form.controls.phone.errors as errors">
            <div
              class="typography typography-body red"
              *ngIf="errors.required"
            >
              Обязательное поле
            </div>
            <div
              class="typography typography-body red"
              *ngIf="errors.phoneFormat as errorText"
            >
              {{ errorText }}
            </div>
          </ng-container>
        </ng-container>
      </div>

      <div class="grid-item grid-6 grid-xs-12 form-item form-item-required">
        <div
          class="typography typography-headline typography-medium form-item-label"
        >
          Дата приема
        </div>
        <nz-date-picker
          nzFormat="dd.MM.yyyy"
          nzInputReadOnly="true"
          formControlName="date"
          nzAllowClear="false"
          [nzDisabledDate]="disabledDate"
        ></nz-date-picker>

        <ng-container
          *ngIf="form.controls.date.touched && form.controls.date.errors"
        >
          <div
            class="typography typography-body red"
            *ngIf="form.controls.date.errors.required"
          >
            Обязательное поле
          </div>
        </ng-container>
      </div>

      <div class="grid-item grid-6 grid-xs-12 form-item form-item-required">
        <div
          class="typography typography-headline typography-medium form-item-label"
        >
          Желаемое время приема
        </div>
        <nz-time-picker
          nzFormat="HH:mm"
          nzInputReadOnly="true"
          formControlName="time"
          nzAllowEmpty="false"
          nzHideDisabledOptions="true"
          [nzDefaultOpenValue]="defaultTime"
          [nzDisabledHours]="disabledHours$ | async"
          [nzDisabledMinutes]="disabledMinutes$ | async"
        ></nz-time-picker>

        <ng-container
          *ngIf="form.controls.time.touched && form.controls.time.errors"
        >
          <div
            class="typography typography-body red"
            *ngIf="form.controls.time.errors.required"
          >
            Обязательное поле
          </div>
        </ng-container>
      </div>

      <div class="grid-item grid-6 grid-xs-12 form-item form-item-required">
        <div
          class="typography typography-headline typography-medium form-item-label"
        >
          Город
        </div>
        <nz-select
          nzShowSearch
          class="full-width"
          formControlName="cityName"
          (click)="getCities()"
          [nzNotFoundContent]="emptySearch"
          data-testid="city-name"
        >
          <ng-container *ngFor="let city of cities$ | async">
            <nz-option
              *ngIf="!(isLoadingCities$ | async)"
              [nzLabel]="city"
              [nzValue]="city"
            ></nz-option>
          </ng-container>

          <nz-option
            nzDisabled
            nzCustomContent
            *ngIf="isLoadingCities$ | async"
          >
            Загрузка...
          </nz-option>
        </nz-select>
      </div>
    </div>

    <div class="mt-24 mb-28 modal-appointment-divider"></div>

    <label class="grid grid-nowrap">
      <div class="checkbox grid-item no-shrink">
        <input
          type="checkbox"
          class="checkbox-input"
          formControlName="accept"
        />
        <div class="checkbox-mark" data-testid="checkbox"></div>
      </div>
      <div
        class="modal-appointment-checkbox typography"
        data-testid="appointment-checkbox-title"
      >
        Я согласен
        <a
          class="typography green-basic"
          target="_blank"
          [href]="
            (storage.configuration$ | async)?.hosts?.main +
              Agreements.PersonalDataProcessingAgreement | formatLink
          "
        >
          с условиями обработки персональных данных
        </a>
      </div>
    </label>

    <div class="mt-24" *ngIf="recaptchaKey$ | async as recaptchaKey">
      <re-captcha
        formControlName="recaptcha"
        badge="inline"
        errorMode="handled"
        size="normal"
        [siteKey]="recaptchaKey"
        (error)="onRecaptchaError()"
      >
      </re-captcha>
    </div>

    <button
      class="button mt-40 relative"
      [disabled]="form.invalid || (isLoadingSubmit$ | async)"
      [class.loading]="isLoadingSubmit$ | async"
      (click)="onSubmit()"
      data-testid="appointment-button"
    >
      <span *ngIf="!(isLoadingSubmit$ | async)">
        Записаться на прием
      </span>
    </button>
  </form>
</ng-container>

<ng-template #emptySearch>
  <div class="empty empty-s">В указанном городе пока нет Центра Хеликс</div>
</ng-template>

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { take } from 'rxjs';
import { NzModalRef } from 'ng-zorro-antd/modal';

import { StorageService } from '@shared/core/services/storage.service';
import { ModalSize } from '@shared/enums/modal-sizes';
import { ChangeCityService } from '@shared/services/changeCity.service';
import { ModalService } from '@shared/services/modal/modal.service';
import { CityComponent } from '@shared/components/city/city.component';
import { YmService } from '@shared/services/yandex-metrica/ym.service';
import { YmGoalLocationCityGuess } from './enums/ym-goal-location-city-guess.enum';

@Component({
  selector: 'app-modal-suggest-city',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './modal-suggest-city.component.html',
  styleUrls: ['./modal-suggest-city.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalSuggestCityComponent {
  constructor(
    private modal: NzModalRef,
    public storage: StorageService,
    private modalService: ModalService,
    private changeCityService: ChangeCityService,
    private ymService: YmService
  ) {
    this.modal.afterClose
      .pipe(take(1))
      .subscribe(() => this.changeCityService.setShowSuggestCity(false));
  }

  openCitiesModal(): void {
    this.ymService.reachGoal(YmGoalLocationCityGuess.LocationCityGuessDenied);
    this.modalService.destroyAll();
    this.modalService.create({
      nzContent: CityComponent,
      nzStyle: {
        width: ModalSize.BigInfo,
      },
      nzFooter: null,
    });
  }

  confirm(): void {
    this.ymService.reachGoal(
      YmGoalLocationCityGuess.LocationCityGuessConfirmed
    );
    this.modalService.destroyAll();
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  BehaviorSubject,
  finalize,
  map,
  Subject,
  switchMap,
  takeUntil,
  throwError,
} from 'rxjs';
import { catchError, filter, first } from 'rxjs/operators';
import {
  addMinutes,
  addMonths,
  differenceInMinutes,
  endOfMinute,
  getHours,
  getMinutes,
  isAfter,
  isBefore,
  isFuture,
  isPast,
  isSameDay,
  startOfDay,
  startOfHour,
} from 'date-fns';

import { CityResponse, SetPractitionerCommand } from '@medindex-webapi';
import { ModalTitle } from '@shared/enums/modal-title.enum';
import { TypeNomenclature } from '@shared/enums/type-nomenclature';
import { Country } from '@shared/enums/country.enum';
import { upperFirstLetter } from '@shared/utils/upper-first-letter.util';
import { YmService } from '@shared/services/yandex-metrica/ym.service';
import { YmGoalAppointment } from '@shared/services/yandex-metrica/enums/appointment.goals';
import { ErrorMessages } from '../../types/error-messages.enum';
import { StorageService } from '../../core/services/storage.service';
import { CustomValidators } from '../../validators/custom-validators';
import { FingerprintService } from '../../services/fingerprint.service';
import { Agreements } from '../../constants/agreements';
import { HttpResponseStatuses } from '../../enums/http-statuses';
import { ModalSize } from '../../enums/modal-sizes';
import { Mask } from '../../enums/mask';
import { ErrorMessageModal } from './constants/error-messages.constants';
import { ConfirmationModal } from './constants/confirmation-modal.constants';
import { IServiceInfo } from './interfaces/modal-appointment-doctor.interfaces';
import { ModalAppointmentDoctorDatasourceService } from './services/modal-appointment-doctor-datasource.service';
import { ModalService } from '@shared/services/modal/modal.service';

@Component({
  selector: 'app-modal-appointment-doctor',
  templateUrl: './modal-appointment-doctor.component.html',
  styleUrls: ['modal-appointment-doctor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalAppointmentDoctorComponent implements OnInit, OnDestroy {
  constructor(
    public storage: StorageService,
    private modal: ModalService,
    private datasource: ModalAppointmentDoctorDatasourceService,
    private fingerprintService: FingerprintService,
    private ymService: YmService
  ) {}

  @Input()
  serviceInfo: IServiceInfo | undefined;

  form: FormGroup | undefined = undefined;
  phoneMask: Mask = Mask.Phone;
  defaultTime: Date = startOfHour(Date.now());
  recaptchaKey$ = this.storage.configuration$.pipe(
    map((config) => config.recaptchaPublicKey)
  );
  disabledHours$ = new BehaviorSubject<any>(() => []);
  disabledMinutes$ = new BehaviorSubject<any>(() => []);

  country$ = new BehaviorSubject<Country>(Country.RU);
  cities$ = new BehaviorSubject<string[]>([]);

  isLoadingCities$ = new BehaviorSubject<boolean>(false);
  isLoadingSubmit$ = new BehaviorSubject<boolean>(false);

  readonly Agreements = Agreements;
  readonly ModalTitle = ModalTitle;
  readonly TypeNomenclature = TypeNomenclature;
  private ngUnsubscribe$ = new Subject<void>();

  ngOnInit(): void {
    this.cities$.next([this.serviceInfo?.cityName || '']);

    this.recaptchaKey$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((recaptchaKey: string): void => {
        this.createForm(this.serviceInfo?.cityName || '', recaptchaKey);
      });

    this.checkAvailabilityTime();
  }

  private createForm(city: string, recaptchaKey: string): void {
    this.form = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        CustomValidators.Name,
      ]),
      phone: new FormControl('', [Validators.required, CustomValidators.Phone]),
      date: new FormControl(null, [Validators.required]),
      time: new FormControl(null, [Validators.required]),
      recaptcha: new FormControl(
        null,
        recaptchaKey ? Validators.required : null
      ),
      cityName: new FormControl(city, [Validators.required]),
      accept: new FormControl(false, [Validators.requiredTrue]),
    });
  }

  private checkAvailabilityTime(): void {
    this.form
      ?.get('date')
      ?.valueChanges.pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((date: Date): void => {
        if (date) {
          const isToday = isSameDay(date, new Date());
          const isLastAvailableDay = isSameDay(date, addMonths(new Date(), 1));

          if (isToday) {
            if (this.isUnavailabilitySelectedTime) {
              this.form?.get('time')?.setValue(null);
            }

            this.disabledHours$.next(() =>
              this.getDisabledHoursInBeginningDay()
            );
            this.disabledMinutes$.next((hour: number) =>
              this.getDisabledMinutesInBeginningDay(hour)
            );

            return;
          }

          if (isLastAvailableDay) {
            if (this.isUnavailabilitySelectedTime) {
              this.form?.get('time')?.setValue(null);
            }

            this.disabledHours$.next(() => this.getDisabledHoursInEndDay());
            this.disabledMinutes$.next((hour: number) =>
              this.getDisabledMinutesInEndDay(hour)
            );

            return;
          }

          this.disabledHours$.next(() => []);
          this.disabledMinutes$.next(() => []);
        }
      });
  }

  private get isUnavailabilitySelectedTime(): boolean {
    const selectTime: Date = this.form?.get('time')?.value;

    return (
      (selectTime && isPast(selectTime)) || isFuture(endOfMinute(selectTime))
    );
  }

  private getDisabledHoursInBeginningDay(): number[] {
    const hours: number = getHours(new Date());

    return this.getDisabledHours(0, hours - 1);
  }

  private getDisabledHoursInEndDay(): number[] {
    const hours: number = getHours(new Date());

    return this.getDisabledHours(hours + 1, 24);
  }

  private getDisabledHours(min: number, max: number): number[] {
    const disabledHours: number[] = [];

    for (let i: number = min; i <= max; i += 1) {
      disabledHours.push(i);
    }

    return disabledHours;
  }

  private getDisabledMinutesInBeginningDay(hour: number): number[] {
    const currentMinute: number = getMinutes(new Date());

    return this.getDisabledMinutes(0, currentMinute, hour);
  }

  private getDisabledMinutesInEndDay(hour: number): number[] {
    const currentMinute: number = getMinutes(new Date());

    return this.getDisabledMinutes(currentMinute, 60, hour);
  }

  private getDisabledMinutes(min: number, max: number, hour: number): number[] {
    const currentHour: number = getHours(new Date());
    const disabledMinutes: number[] = [];

    if (currentHour === hour) {
      for (let i: number = min; i < max; i += 1) {
        disabledMinutes.push(i);
      }
    }

    return disabledMinutes;
  }

  disabledDate = (current: Date): boolean => {
    const now: Date = new Date();

    return (
      isAfter(startOfDay(now), startOfDay(current)) ||
      isBefore(startOfDay(addMonths(now, 1)), startOfDay(current))
    );
  };

  getCities(): void {
    this.setLoadingCities(true);

    this.storage.country$
      .pipe(
        first(),
        filter((country: Country | null): country is Country => !!country),
        switchMap((country: Country) => {
          this.country$.next(country);
          return this.datasource.getCities();
        }),
        map((cities: CityResponse[]): void => {
          const currentCities: string[] = this.getCurrentCities(cities).map(
            (city) => upperFirstLetter(city)
          );
          this.cities$.next(currentCities);
        }),
        finalize(() => this.setLoadingCities(false))
      )
      .subscribe();
  }

  onRecaptchaError(): void {
    this.modal.createError(ErrorMessages.Common);
  }

  onSubmit(): void {
    if (this.form?.invalid) return;

    this.isLoadingSubmit$.next(true);

    const { name, phone, date, time, cityName, recaptcha } = this.form?.value;

    const minutes = differenceInMinutes(time, startOfDay(new Date()));

    const appointmentTime = endOfMinute(addMinutes(startOfDay(date), minutes));

    this.fingerprintService
      .getUserId()
      .pipe(
        catchError((error) => {
          this.modal.createError(ErrorMessages.Common);
          return throwError(() => new Error(error));
        }),
        switchMap((userId: string) => {
          const data: SetPractitionerCommand = {
            googleRecaptchaResponse: recaptcha,
            phone,
            date: appointmentTime,
            cityName,
            name,
            hxid: this.serviceInfo?.hxid || '',
            userId,
          };

          return this.datasource.sendForm(data);
        }),
        finalize(() => this.isLoadingSubmit$.next(false)),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe({
        next: (): void => {
          this.ymService.reachGoal(
            YmGoalAppointment.DoctorAppointmentModalSubmit
          );
          this.form?.reset();
          this.modal.destroyAll();

          this.modal.createSuccess(
            ConfirmationModal.Title,
            ConfirmationModal.Text,
            ModalSize.Form,
            5
          );
        },
        error: (error): void => {
          if (
            error?.error?.errors?.Date?.includes(
              'DateMustBeGreaterOrEqualToday'
            )
          ) {
            this.modal.createError(ErrorMessageModal.AppointmentDateInPast);

            return;
          }

          if (error.status === HttpResponseStatuses.BadRequest) {
            this.form?.markAllAsTouched();

            this.modal.createError(ErrorMessageModal.CheckFields);
            return;
          }

          this.modal.createError(ErrorMessages.Common);
        },
      });
  }

  private setLoadingCities(isLoading: boolean): void {
    this.isLoadingCities$.next(isLoading);
  }

  private getCurrentCities(cities: CityResponse[]): string[] {
    return cities
      .filter(
        (city: CityResponse) =>
          city && city.name && city.countryCode === this.country$.value
      )
      .map((city: CityResponse) => city.name || '')
      .sort((a: string, b: string): number => {
        if (a.toLowerCase() < b.toLowerCase()) {
          return -1;
        }
        if (a.toLowerCase() > b.toLowerCase()) {
          return 1;
        }
        return 0;
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}

<div class="typography typography-paragraph" data-testid="warning-msg">
  При смене города {{ oldCityName ? 'с ' + oldCityName : '' }} {{ newCityName ? 'на ' + newCityName : '' }} список исследований в корзине может быть изменен. Продолжить?
</div>
<div class="row row-gutter-12 grid grid-gutter-12">
  <div class="row-item grid-item">
    <button
      class="button button-primary"
      (click)="confirm()"
      data-testid="yes-button"
    >
      Да
    </button>
  </div>
  <div class="row-item grid-item">
    <button class="button button-subtle" (click)="close()" data-testid="no-button">
      Нет
    </button>
  </div>
</div>

import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  distinctUntilChanged,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
} from 'rxjs';

import { CatalogClient, GetCatalogCategoriesResponse } from '@medindex-webapi';
import { StorageService } from '@shared/core/services/storage.service';
import { HelixRoutes } from '@shared/enums/routes';
import { DatasourceService } from '@shared/core/services/datasource.service';

/** Динамическая информация для пунктов сквозного меню. */
@Injectable()
export class NavigationMenuService implements OnDestroy {
  private _catalogCategories$ = new BehaviorSubject<
    GetCatalogCategoriesResponse[] | null
  >(null);
  catalogCategories$ = this._catalogCategories$.asObservable();

  /** Включен ли новый флоу записи на прием в текущем городе. */
  isAppointmentAvailable$: Observable<boolean> = this.storage.city$.pipe(
    map((city) => city.isPractitionerAppointmentAvailable),
    distinctUntilChanged()
  );

  isHelixLiteAvailable$: Observable<boolean> = this.storage.city$.pipe(
    map((city) => city.isHelixLite),
    distinctUntilChanged()
  );

  private doctorServicesCategoryUrl$ = this.catalogCategories$.pipe(
    map((categories) => {
      const categoryUrlPart =
        categories?.find(
          (category) => category.id === this.doctorServicesCategoryId
        )?.urlPart ?? null;
      return `/${HelixRoutes.Catalog}/${
        categoryUrlPart
          ? categoryUrlPart
          : this.fallbackDoctorServicesCategoryUrlPart
      }`;
    })
  );

  /** URL для пункта меню "Запись к врачу". */
  appointmentUrl$: Observable<string> = this.isAppointmentAvailable$.pipe(
    switchMap((isAvailable) => {
      return isAvailable
        ? of(`/${HelixRoutes.PractitionerAppointment}`)
        : this.doctorServicesCategoryUrl$;
    })
  );

  /** ID категории "Врачебные услуги". */
  readonly doctorServicesCategoryId = 191;
  /** Последняя часть URL страницы "Врачебные услуги" в Каталоге (на случай, если не придет с сервера). */
  private readonly fallbackDoctorServicesCategoryUrlPart = 'vrachebnye-uslugi';

  private ngUnsubscribe$ = new Subject<void>();

  constructor(
    private catalogClient: CatalogClient,
    private storage: StorageService,
    private dataSource: DatasourceService
  ) {
    this.loadCatalogCategories();
  }

  private loadCatalogCategories(): void {
    this.catalogClient
      .getCatalogCategories()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe({
        next: (categories) => this._catalogCategories$.next(categories),
        error: (error) => this.dataSource.errorHandler(error),
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}

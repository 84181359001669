<app-visually-impaired></app-visually-impaired>
<app-helix-header data-web-only></app-helix-header>
<div class="typography">
  <router-outlet></router-outlet>
</div>
<app-helix-footer data-web-only
  [phoneNumbers]="phoneNumbers$ | async"
></app-helix-footer>

<app-mobile-bottom-navigation data-web-only></app-mobile-bottom-navigation>

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ModalSize } from '@shared/enums/modal-sizes';
import { ChangeCityService } from '@shared/services/changeCity.service';
import { ModalService } from '@shared/services/modal/modal.service';
import { first } from 'rxjs';
import { CityComponent } from '../city/city.component';
import { NzModalRef } from 'ng-zorro-antd/modal';

/** Модалка подтверждения смены города. */
@Component({
  selector: 'app-modal-change-city-warning',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './modal-change-city-warning.component.html',
  styleUrls: ['./modal-change-city-warning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalChangeCityWarningComponent {
  /**
   * Если true ("глупый" режим), кнопки просто аналогичны Ok и Cancel из модалок NG-ZORRO.
   * В ином случае исполняет логику подтверждения/смены города.
   */
  @Input() isDummy = false;
  @Input() oldCityName?: string | null;
  @Input() newCityName?: string | null;

  /**
   * ID города, выбранного для пользователя. Если 0, попросит выбрать город в новой модалке.
   * (используется только не в "глупом" режиме isDummy == false)
   */
  @Input() cityId = 0;

  constructor(
    private modalService: ModalService,
    private changeCityService: ChangeCityService,
    private modalRef: NzModalRef
  ) {}

  confirm(): void {
    if (this.notifyNzAndDestroyAllModals(true)) {
      return;
    }

    if (this.cityId > 0) {
      this.changeCityService
        .changeCity({ id: this.cityId, name: '', alias: '' })
        .pipe(first())
        .subscribe();
    } else {
      this.modalService.create({
        nzContent: CityComponent,
        nzStyle: {
          width: ModalSize.BigInfo,
        },
        nzFooter: null,
      });
    }
  }

  close(): void {
    this.notifyNzAndDestroyAllModals(false);
  }

  /**
   * Если в "глупом" режиме, то посылает событие для nzOnOk/nzOnCancel.
   * Затем, вне зависимости от режима, закрывает модалку.
   * @param isOk Для клика по подтверждению смены города, или для отмены.
   * @returns true, если послали событие для nzOnOk/nzOnCancel до закрытия модалки, иначе - false.
   */
  private notifyNzAndDestroyAllModals(isOk: boolean): boolean {
    if (this.isDummy) {
      if (isOk) {
        this.modalRef.triggerOk();
      } else {
        this.modalRef.triggerCancel();
      }
    }
    this.modalService.destroyAll();
    return this.isDummy;
  }
}

<div class="grid grid-column">
  <div class="grid-item logo">
    <a
      class="block mt-4 mb-8"
      routerLink="/"
      aria-label="Главная"
      [innerHtml]="logoSvg | safeHtml"
    ></a>
  </div>
  <div class="grid-item national-brand">
    <img
      class="img-brand"
      loading="lazy"
      decoding="auto"
      alt="Логотип компании"
      [src]="nationalBrandSrc"
    />
  </div>
</div>

export const BusinessLogicErrorMap: { [key: string]: string } = {
  CityIdNotFound:
    'К сожалению, в выбранном городе анализы недоступны для заказа. Укажите другой город или обратитесь в Контакт-центр по телефону: +7 800 700 03 03.',
  CityNameNotFound:
    'Пожалуйста, укажите город еще раз или выберите другой.  \n' +
    '\n' +
    'Если возник вопрос – обратитесь в Контакт-центр по телефону: +7 800 700 03 03.',
  SomeItemFromCartMissingFromDb:
    'В вашей корзине есть анализ, недоступный для заказа. Пожалуйста, удалите его.',
  DiagnosticCentersNotFound:
    'К сожалению, в выбранном городе анализы недоступны для заказа. Укажите другой город или обратитесь в Контакт-центр по телефону: +7 800 700 03 03. ',
  NotDiagnosticCenterIsAvailableForCartWithSelectedPaymentType:
    'Выбранный способ оплаты недоступен для анализов в заказе.  \n' +
    '\n' +
    'Пожалуйста, измените способ оплаты заказа.',
};
